<template>
	<div>
		<ComponentInventory></ComponentInventory>
	</div>
</template>

<script type="text/javascript">
import ComponentInventory from '@/components/ComponentInventory'

export default {
	name: 'Inventory',
	components:{
		ComponentInventory,
	},
	beforeMount(){
		console.log('ffff')
		
	}
}
	
</script>

<style scope>
</style>

<template>
	<v-data-table :headers="headers" :items="products" sort-by="calories" class="elevation-1" show-select v-model="selected">
		<template v-slot:top>
			<v-toolbar flat>
				<v-toolbar-title>Инвентаризация</v-toolbar-title>
				<v-divider  class="mx-4" inset vertical></v-divider>
				<v-spacer></v-spacer>
				<v-dialog v-model="dialog" max-width="500px">
					<template v-slot:activator="{ on, attrs }">
						<v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">Добавить</v-btn>
					</template>
					<v-card>
						<v-card-title>
							<span class="headline">{{ formTitle }}</span>
						</v-card-title>
						<v-card-text>
							<v-container>
								<v-row>
									<v-col cols="12" sm="12" md="12">
										<v-text-field v-model="editedItem.barcode" autofocus label='Штрихкод'></v-text-field>
									</v-col>
									<v-col cols="12" sm="12" md="12">
										<v-autocomplete v-model="editedItem.name" :items="productslist" label="Товар" dense ></v-autocomplete>
									</v-col>
									<v-col cols="12" sm="6" md="4">
										<v-text-field v-model="editedItem.count" label="Количество"></v-text-field>
									</v-col>
									<v-col cols="12" sm="6" md="4">
										<v-text-field v-model="editedItem.purchase" label="Цена закупки (за ед)" @change="changeMarkup"></v-text-field>
									</v-col>
									<v-col cols="12" sm="6" md="4">
										<v-text-field v-model="editedItem.purchase_summ" label="Цена закупки (Итого)" @change="changeMarkup"></v-text-field>
									</v-col>
									<v-col cols="12" sm="6" md="4">
										<v-text-field v-model="editedItem.selling" label="Цена продажи (за ед)" @change="changeMarkup"></v-text-field>
									</v-col>
									<v-col cols="12" sm="6" md="4">
										<v-text-field v-model="editedItem.markup"  label="Наценка (%)" @change="changeMarkupProc"></v-text-field>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="blue darken-1" text @click="close">Отмена</v-btn>
							<v-btn color="blue darken-1" text @click="save">Сохранить</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>

				<v-dialog v-model="dialogDelete" max-width="500px">
					<v-card>
						<v-card-title class="headline">Вы уверены что хотите удалить позицию?</v-card-title>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
							<v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
							<v-spacer></v-spacer>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-toolbar>
		</template>

		<template v-slot:item.actions="{ item }">
			<v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
			<v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
		</template>
		
		<template v-slot:no-data>
			<v-btn color="primary" @click="initialize">Reset</v-btn>
		</template>

		<template v-slot:body.append>
			<tr>
				<td>-</td>
				<td><v-text-field ref="barcode"  v-model="editedItem.barcode"  :rules="rules" autofocus v-on:keyup.enter="createNew"></v-text-field></td>
				<td><v-autocomplete ref="product" v-model="editedItem.name" :items="productslist" ></v-autocomplete></td>
				<td><v-text-field   v-model="editedItem.count" counter="0" :rules="rules" label="Количество"></v-text-field></td>
				<td><v-text-field   v-model="editedItem.purchase" counter="0" :rules="rules" label="Цена за ед" @change="changeMarkup"></v-text-field></td>
				<td><v-text-field   v-model="editedItem.purchase_summ" counter="0" label="Итого" disabled></v-text-field></td>
				<td><v-text-field   v-model="editedItem.selling" counter="0" :rules="rules" label="Цена продажи" @change="changeMarkup"></v-text-field></td>
				<td><v-text-field   v-model="editedItem.markup"  label="Наценка" disabled ></v-text-field></td>
				<td><v-btn color="blue darken-1" text @click="save">Добавить</v-btn></td>
			</tr>

			<tr>
				<th colspan=3>ИТОГО</th>
				<th>{{ getCountProducts.count }}</th>
				<th>{{ getCountProducts.purchase }}</th>
				<th>{{ getCountProducts.purchase_summ }}</th>
				<th>{{ getCountProducts.selling }}</th>
				<th colspan=2></th>
			</tr>
		</template>
	</v-data-table>
</template>



<script>
export default {
	data: () => ({
		dialog: false,
		dialogDelete: false,
		singleSelect: false,
		selected: [],
		max25chars: v => v.length <= 25 || 'Input too long!',
		productslist: [
		'Товар 1',
		'Товар 2',
		'Товар 3',
		'Товар 4',
		'Товар 5',
		'Очень динное название товара, что бы оценить на сколько оно поместится в таблицу'
		],
		headers: [
		{ text: 'Штрих', value: 'barcode' },
		{
			text: 'Товарная позиция',
			align: 'start',
			sortable: false,
			value: 'name',
		},
		{ text: 'Количество', value: 'count' },
		{ text: 'Цена закупки (за ед)', value: 'purchase' },
		{ text: 'Цена закупки (итого)', value: 'purchase_summ'},
		{ text: 'Цена продажи (за ед)', value: 'selling' },
		{ text: 'Наценка (%)', value: 'markup' },
		{ text: 'Actions', value: 'actions', sortable: false },
		],
		products: [],
		editedIndex: -1,
		editedItem: {
			barcode: '',
			name: '',
			count: 0,
			purchase: 0,
			purchase_summ:0,
			selling: 0,
			markup: 0,
		},
		defaultItem: {
			barcode: '',
			name: '',
			count: 0,
			purchase: 0,
			purchase_summ: 0,
			selling: 0,
			markup: 0,
		},
	}),

	computed: {
		rules() {
			const rules = []
			const rule = (v)=>{
				if (v){
					return /^[0-9.,]+$/g.test(v) || 'Только цифры' 
				} else {
					return true
				}
			}
			rules.push(rule)
			return rules
		},
		formTitle () {
			return this.editedIndex === -1 ? 'Новая позиция' : 'Редактирование'
		},
		getMarkup() {
			return 0
		},
		getCountProducts() {
			let count = 0
			let purchase = 0
			let selling = 0
			let purchase_summ = 0
			//console.log(this.products)
			//let num  = 9500
			//console.log(num.toLocaleString('ru-RU'))
			for (var i in this.products) {
				count += Number(this.products[i].count)
				purchase += Number(this.products[i].purchase)
				purchase_summ += Number(this.products[i].purchase_summ)
				selling += Number(this.products[i].selling)
			}
			return {count:count, purchase_summ:purchase_summ, purchase:purchase, selling:selling}
		},
	},

	watch: {
		dialog (val) {
			val || this.close()
		},
		dialogDelete (val) {
			val || this.closeDelete()
		},
	},

	created () {
		this.initialize()
	},

	methods: {
		createNew() {
			//console.log("createNew")
			if (this.editedItem.barcode != '') {
				this.save()
			}
		},
		changeMarkupProc() {
			this.editedItem.selling = (Number(this.editedItem.purchase) * ((Number(this.editedItem.markup/100)+1))).toFixed(2)
		},
		changeMarkup() {
			this.editedItem.markup = (((Number(this.editedItem.selling) / Number(this.editedItem.purchase))-1)*100).toFixed(2)
			this.editedItem.purchase_summ = (Number(this.editedItem.purchase)*Number(this.editedItem.count))
		},
		initialize () {
			this.products = [
			{ name: 'Товар 1', barcode:'000', count: 159, purchase: 100, purchase_summ:15900, selling: 120, markup: 20.0, },
			{ name: 'Товар 2', barcode:'000', count: 380, purchase: 25,  purchase_summ:9500, selling: 75,  markup: 200.0, },
			]
		},

		editItem (item) {
			this.editedIndex = this.products.indexOf(item)
			this.editedItem = Object.assign({}, item)
			this.dialog = true
		},

		deleteItem (item) {
			this.editedIndex = this.products.indexOf(item)
			this.editedItem = Object.assign({}, item)
			this.dialogDelete = true
		},

		deleteItemConfirm () {
			this.products.splice(this.editedIndex, 1)
			this.closeDelete()
		},

		close () {
			this.dialog = false
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem)
				this.editedIndex = -1
			})
		},

		closeDelete () {
			this.dialogDelete = false
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem)
				this.editedIndex = -1
			})
		},

		save () {
			//console.log('save')
			this.$refs.barcode.focus()
			//console.log(this)
			if (this.editedIndex > -1) {
				//console.log('save')
				//this.$refs.product.focus()
				//console.log(this.products[this.editedIndex])
				Object.assign(this.products[this.editedIndex], this.editedItem)
			} else {
				this.products.push(this.editedItem)
			}

			this.close()
		},
	},
}
</script>


<style scope>
</style>
